export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB2t8ik-hMD8MkN8dLRnmVjYzW_Kp2MRYI",
    authDomain: "sa-safememunicab.firebaseapp.com",
    databaseURL: "https://sa-safememunicab-default-rtdb.firebaseio.com",
    projectId: "sa-safememunicab",
    storageBucket: "sa-safememunicab.appspot.com",
    messagingSenderId: "1044902507898",
    appId: "1:1044902507898:android:c3d164dbae6a5432c5d2c3",
    measurementId: "G-8CL2NFB95B"
  },
  langArr: [
    { name: 'English', code: 'en' }
  ],
  mapOptions: {
    zoom: 17,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false
  }
};



export let POSITION_INTERVAL = 10 * 1000; // 10s for refreshing geolocation

export let DEAL_STATUS_PENDING = 'pending';
export let DEAL_STATUS_ACCEPTED = 'accepted';
export let DEAL_TIMEOUT = 20; // 20 seconds

export let TRIP_STATUS_WAITING = 'waiting';
export let TRIP_STATUS_GOING = 'going';
export let TRIP_STATUS_FINISHED = 'finished';
export let TRIP_STATUS_CANCELED = 'canceled';
export let TRANSACTION_TYPE_WITHDRAW = 'withdraw';

export let PLAY_AUDIO_ON_REQUEST = true;
export let AUDIO_PATH = "./assets/audio/sound.mp3" //must have mp3 file

export let DEFAULT_AVATAR = './assets/img/default.png';
